import React, {useRef, useState} from 'react'

import {Heading, Text, Flex, InputGroup, InputRightElement,IconButton, createStandaloneToast,Icon,Modal,Box,Input,ModalBody, ModalContent, ModalOverlay, ModalFooter, ModalCloseButton, ModalHeader, Button} from '@chakra-ui/react'
import { ArrowForwardIcon, LinkIcon, CopyIcon} from '@chakra-ui/icons'

import {motion} from 'framer-motion'
import {FiCheck} from 'react-icons/fi'
import axios from 'axios'
import GradWrapper from './util/GradWrapper'

const MotionBox = motion(Box)


const WaitlistModal = ({open, setOpen, referralId}) => {

    const textRef = useRef()

    const [loading,setLoading] = useState(false)

    const [success,setSuccess] = useState(false)

    const [form, setForm] = useState({email: '', name: ''})

    const [link, setLink] = useState("")


    const toast = createStandaloneToast()


    const register = async () => {

        try{

            if(!form.email){
                toast({
                    title: "Please enter your email!",
                    status: "error",
                    position: 'top',
                    duration: 1000,
                    isClosable: true
                })
                return
            }

            setLoading(true)


            const {data} = await axios.post("https://taufinance.com/preuser/register", 
                {   
                    ...form, 
                    referral : referralId || "",
                }
            )

            setLoading(false)
            setSuccess(true)

            // setCoupons((coupons) => [data, ...coupons])

            
        } catch (err) {

            toast({
                title: "Server Error",
                status: "error",
                position: 'top',
                duration: 1000,
                isClosable: true
            })

            setLoading(false)


        }

    }

    const getLink = async () => {

        try{

            if(!form.name){
                toast({
                    title: "Please enter your name!",
                    status: "error",
                    position: 'top',
                    duration: 1000,
                    isClosable: true
                })
                return
            }

            setLoading(true)

            const {data} = await axios.post("https://taufinance.com/preuser/generate-link", {
                ...form,
                firstName: form.name.split(" ")[0],
                lastName: form.name.split(" ")[1]
            })
            setLink(`https://usetau.com/referral/${data.id}`)
            setLoading(false)
            setSuccess(true)
            
        } catch (err) {

            toast({
                title: "Server Error",
                status: "error",
                position: 'top',
                duration: 1000,
                isClosable: true
            })

            setLoading(false)


        }

    }

    const handleClose = () => {
        setOpen(false)
        setSuccess(false)
        setForm({title: '', desc: ''})
    }



    return(

        <Modal initialFocusRef={{md: textRef, sm: null}} size="lg" isOpen={open} onOverlayClick={handleClose}  onClose={() => setOpen(false)} isCentered>
        <ModalOverlay>

            <ModalContent bg="black">
            <GradWrapper  marginTop="4" justifyContent="center"  borderRadius="2xl" >

            <Box borderRadius="2xl" bg="black" padding="3">

              <ModalHeader marginTop="4" >
                  {success ? 
                  <>
                    <MotionBox initial={{opacity: 0}} animate={{opacity: 1, duration: 0.8}}> 
                        <Heading fontSize="3xl"> Awesome, you're in line! </Heading>
                    </MotionBox>
                    <MotionBox  initial={{opacity: 0}} animate={{opacity: 1, transition: {duration: 1}}}> 

                        <Text marginTop="1" fontWeight="normal" fontSize="lg" color="gray.200"> Refer your friends to get up to an additional 1% APY </Text>
                    </MotionBox>
                  </>
                  :
                  <>
                  <MotionBox initial={{opacity: 0}} animate={{opacity: 1, duration: 0.8}}> 
                      <Heading fontSize="3xl"> Welcome aboard </Heading>
                  </MotionBox>
                  <MotionBox initial={{opacity: 0}} animate={{opacity: 1, transition: {duration: 1}}}> 

                      <Text marginTop="1" fontWeight="normal" color="gray.200"> We can't wait to change how you use money </Text>
                  </MotionBox>
                </>
                  }
               

                <ModalCloseButton marginTop="4" _focus={{boxShadow: 'none'}}/>      
              </ModalHeader> 

    
               <ModalBody borderRadius="2xl" marginBottom="4">
                   { success ?

                   link ? 
                   <Flex key={2} w="100%" justifyContent="space-between">
                        <MotionBox w="85%" initial={{y: 40, opacity: 0}} animate={{y: 0, opacity: 1, transition: {type:'spring', stiffness: 50, duration: 0.3, delay: 0.2}}}>
                            <Input variant="flushed" ref={textRef} value={link} marginBottom="4"  focusBorderColor="#888"></Input>
                        </MotionBox>
                        <MotionBox whileHover={{x: 2}}>
                            <IconButton isLoading={loading} onClick={() => {
                                navigator.clipboard.writeText(link)
                                toast({
                                    title: "Link copied to clipboard!",
                                    status: "success",
                                    position: 'top',
                                    duration: 3000,
                                    isClosable: true
                                })
                            }} icon={<CopyIcon/>} _focus={{outline: 'none'}}></IconButton>
                        </MotionBox>
                    </Flex>
                    :
                    <Flex key={1} w="100%" flexDir="column" justifyContent="space-between" alignItems="center">
                        <MotionBox w="100%" initial={{y: 40, opacity: 0}} animate={{y: 0, opacity: 1, transition: {type:'spring', stiffness: 50, duration: 0.3, delay: 0.2}}}>
                            <Input  variant="flushed" ref={textRef} value={form.name} onChange={(e) => setForm({...form, name: e.target.value})} placeholder="Enter your full name to get a referral link" marginBottom="4"  focusBorderColor="#888"></Input>
                        </MotionBox>
                        <MotionBox marginTop="4" initial={{opacity: 0}} animate={{opacity: 1, transition: {delay: 0.3, type: 'spring', duration: 0.3 }}} whileHover={{y: !form.name ? 0 : 2}}>
                            <GradWrapper borderRadius="md">
                                <Button isLoading={loading} disabled={!form.name.includes(" ")} onClick={getLink} size="sm" variant="solid" rightIcon={<LinkIcon/>} _focus={{outline: 'none'}}>Get my link</Button>
                            </GradWrapper>
                        </MotionBox>
                    </Flex>
                    
                    :
                    <Flex key={2} w="100%" justifyContent="space-between">
                        <MotionBox w="85%" initial={{y: 40, opacity: 0}} animate={{y: 0, opacity: 1, transition: {type:'spring', stiffness: 50, duration: 0.3, delay: 0.2}}}>
                            <Input  variant="flushed" ref={textRef} value={form.email} onChange={(e) => setForm({...form, email: e.target.value})} placeholder="Email" marginBottom="4"  focusBorderColor="#888"></Input>
                        </MotionBox>
                        <MotionBox whileHover={{x: 2}}>
                            <IconButton isLoading={loading} onClick={register} icon={<ArrowForwardIcon/>} _focus={{outline: 'none'}}></IconButton>
                        </MotionBox>
                    </Flex>
                    
                   }
                  
               </ModalBody>
               </Box>
               </GradWrapper>
            </ModalContent>


        </ModalOverlay>

   </Modal>              

    )
}

export default WaitlistModal