import { Heading, Flex, Text, Box, Button, Lorem} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from "@chakra-ui/react"
import { motion } from 'framer-motion'
import WidthWrap from '../components/util/WidthWrap'
import mock from './mock3.png'
import WaitlistModal from '../components/WaitlistModal'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Helmet from 'react-helmet'
import axios from 'axios'
const bgColor = "#1b1d23"


const Landing = () => {

    const [open, setOpen] = useState(false)

    const [referralName, setReferralName] = useState(null)

    let { referralId } = useParams();

    useEffect(() => {
        if(referralId){
            setOpen(true)
            getData()
        }
    }, [])

    const getData = async () => {
        try{
            const {data} = await axios.get(`https://taufinance.com/referral/${referralId}/owner`)
            setReferralName(data.firstName)
            console.log(data)
        } catch (err) {
            console.log(err)
        }
    }

    return(
        <WidthWrap >
            {referralId && 
                <Helmet>
                    <title>{ referralName || "Preston" } has referred you!</title>
                    <desc>Come join the financial revolution</desc>
                </Helmet>
            }
            <WaitlistModal open={open} setOpen={setOpen} referralId={referralId} />
            <Flex w="100%" justifyContent={{md :"space-around", sm: '', base: ''}} alignItems={{sm: 'center', md: '', base: 'center'}} flexDir={{md: "row", sm: 'column', base:'column'}}>
                <Flex marginTop={{base: '4', md: '12'}} w={{base: '85%', md : "40%", sm : '85%'}} alignItems={{base : 'center', md:"flex-start", sm: "center" }} justifyContent="flex-start" flexDirection="column">
                    <Heading w="100%" fontWeight="semibold" textAlign={{base: 'center', md:'left', sm: "center"}} fontSize={{md: '5xl', sm: '3xl', base : '3xl'}}>
                        Money Done <Box 
                            as="span" color="blue.50"
                            bgGradient="linear(to-l, #0052D4, #6FB1FC)"
                            bgClip="text" 
                        >
                        Right
                        </Box>.
                    </Heading>

                    <Text w="100%" textAlign={{md:'left', sm: "center", base: 'center'}} marginTop={{base: '3', md: '4'}} fontSize={{base: "medium", md:'xl'}} fontWeight="normal" color="gray.300">
                        6% APY on all accounts.  <br/>
                        No fees. Instant peer-to-peer transactions. 
                    </Text>

                    <Box marginTop="4" justifyContent="center" borderRadius="lg" padding="2px" bgGradient ="linear(to-l, #0052D4, #6FB1FC)"  >
                        <Button onClick={() => {
                            setOpen(true)
                            console.log('wow')
                        }} borderRadius="lg" _hover={{bg : "black"}} _focus={{boxShadow: 'none'}} variant="solid" background="linear(to-l, #0052D4, #6FB1FC)" >
                            <Text marginLeft="3" marginRight="3"> Join the waitlist</Text>
                        </Button>
                    </Box>
                </Flex>
                <Flex justifyContent="center" marginTop={{base: '12', sm:'12', md:'12'}} maxH={{ base:'50vh', sm: '50vh',md:"65vh"}} w={{base: '80%', md : "40%", sm : '80%'}} >
                    {/* <Box w={{md: '100%'}} h={{sm: '100%'}} maxH={{sm: '60vh', md : ""}} maxW={{sm: "", md: "40%"}}> */}
                        <motion.img style={{objectFit: 'contain'}} initial={{x: 300}} animate={{x: 0, transition: {type:'spring', duration: 0.8, stiffness: 80}}} whileHover={{x: 20, transition: {type: 'spring'}}}  src={mock}/>
                    {/* </Box> */}
                </Flex>
             
            </Flex>
        </WidthWrap>
    )
}

export default Landing