import {Box} from '@chakra-ui/react'

const GradWrapper = (props) => {

    // TODO add 100% vh mobile styling fix

    return(
    <Box padding="2px" bgGradient ="linear(127deg, #feac5e, #c779d0, #4bc0c8)" {...props}>
            {props.children}
    </Box>
    )
}

export default GradWrapper