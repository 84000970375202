import './styles/App.css';

import Landing from './pages/Landing'
import Waitlist from './pages/Landing'

import { ChakraProvider } from '@chakra-ui/provider';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Navbar from './components/Navbar';

import { extendTheme, createBreakpoints } from "@chakra-ui/react"
import FullWrap from './components/util/FullWrap';
import { useEffect } from 'react';
import {Helmet} from 'react-helmet'

// 2. Add your color mode config
const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
}
// 3. extend the theme
const theme = extendTheme({ config })


const App = () => {

  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <title>Tau Finance</title>
        <desc>Join the financial revolution</desc>
      </Helmet>
      <FullWrap bg="black">
        <Router>
          <Navbar/>
          <Switch>
            <Route exact path="/waitlist" component={Waitlist}/>
            <Route path="/referral/:referralId" component={() => <Landing/>}/>
            <Route path="/" component={Landing}/>
          </Switch>
        </Router>
      </FullWrap>
    </ChakraProvider>
  );
}

export default App;
