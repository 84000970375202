import {Box} from '@chakra-ui/react'

const FullWrap = (props) => {

    // TODO add 100% vh mobile styling fix

    return(
    <Box w="100wh" minH="100vh" bg={props.bg}>
        {props.children}
    </Box>
    )
}

export default FullWrap