import WidthWrap from "./util/WidthWrap"
import {Flex, Text, Box, Image, Button, Divider, DrawerBody, DrawerContent, DrawerOverlay, Collapse} from '@chakra-ui/react'
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'
import logo from './7.svg'
import { useState } from "react"
import WaitlistModal from './WaitlistModal'
import GradWrapper from "./util/GradWrapper"
const links = [
    {title: 'About', route: '/about'},
    {title: 'Learn', route: '/learn'},
    {title: 'Careers', link: 'https://www.linkedin.com/company/taufinance/', out: true},
    {title: 'Blog', route: '/blog', },

]

const MiniLink = ({link}) => {

    return(
        <Flex marginLeft="6" marginRight="6" alignItems="center">
            {link.out ?
             <a href={link.link}>
                <motion.div whileHover={{y: -0.5, transition: {type: 'spring'}}}>
                    <Text fontSize="md" fontWeight="light"> {link.title} </Text>
                </motion.div>
            </a>
            :
            <Link to={link.route}>
                <motion.div whileHover={{y: -0.5, transition: {type: 'spring'}}}>
                    <Text fontSize="md" fontWeight="light"> {link.title} </Text>
                </motion.div>
            </Link>
            }
           
        </Flex>
    )
}


const MobileNavLink = ({link}) => {

    return(
        <>
            <Flex paddingLeft="10" marginBottom="2" marginTop="2" alignItems="center">
                <Link to={link.route}>
                    <motion.div whileHover={{x: 4, transition: {type: 'spring'}}}>
                        <Text fontSize="xl" fontWeight="normal"> {link.title} </Text>
                    </motion.div>
                </Link>
            </Flex>
            <Divider/>
        </>
    )
}

const MenuIcon = ({w, h, color}) => {
    return(
      <svg style={{cursor: 'pointer'}} width={w} height={h} viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 3H3V1H1V3Z" fill={color}></path><path fill-rule="evenodd" clip-rule="evenodd" d="M1 9H3V7H1V9Z" fill={color}></path><path fill-rule="evenodd" clip-rule="evenodd" d="M1 15H3V13H1V15Z" fill={color}></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7 3H9V1H7V3Z"fill={color}></path><path fill-rule="evenodd" clip-rule="evenodd" d="M13 3H15V1H13V3Z" fill={color}></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7 9H9V7H7V9Z" fill={color}></path><path fill-rule="evenodd" clip-rule="evenodd" d="M13 9H15V7H13V9Z" fill={color}></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7 15H9V13H7V15Z" fill={color}></path><path fill-rule="evenodd" clip-rule="evenodd" d="M13 15H15V13H13V15Z" fill={color}></path></svg>
    )
  }

const Navbar = () => {

    const [drawerVisibility, setDrawerVisibility] = useState(false)

    const [open, setOpen] = useState(false)

    return (
    <WidthWrap bg="#000">
        <WaitlistModal open={open} setOpen={setOpen}/>
        <Flex flexDirection="column" w="100%">
            <Flex w="100%" padding="10" paddingBottom="8" justifyContent="space-between" alignItems="center">
                <Flex ß alignItems="center">
                    <Image w="12" marginRight="8" src={logo}/>
                    {/* <Text  fontSize="2xl" fontWeight="thin" letterSpacing="5px">tau</Text> */}
                </Flex>
                
                
                <Flex display={{base: 'none', sm: "none", md:'flex'}} justifyContent="space-between">
                    {links.map((link) => <MiniLink link={link} />)}
                </Flex>

                <Flex display={{base: 'none', sm: "none", md:'flex'}} alignContent="center">
                    <MiniLink link={{route: 'faq', title: 'FAQ'}}/>
                    <GradWrapper borderRadius="lg" padding="1px" bgGradient="linear(to-l, #0052D4, #6FB1FC)">
                        <Button onClick={() => setOpen(true)} borderRadius="lg" _hover={{bgGradient :"linear(to-l, #0052D4, #6FB1FC)"}} _focus={{boxShadow: 'none'}} variant="solid" background="black" >
                                <Text marginLeft="3" marginRight="3"> Sign Up </Text>
                            </Button>
                    </GradWrapper>
                    {/* <Flex justifyContent="center" borderRadius="lg" padding="1px" bgGradient ="linear(to-l, #0052D4, #6FB1FC)"  >
                        <Button onClick={() => setOpen(true)} borderRadius="lg" _hover={{bgGradient :"linear(to-l, #0052D4, #6FB1FC)"}} _focus={{boxShadow: 'none'}} variant="solid" background="black" >
                            <Text marginLeft="3" marginRight="3"> Sign Up </Text>
                        </Button>
                    </Flex> */}
                </Flex>

                <Flex display={{base: 'flex', sm: "flex", md:'none'}}>
                    <motion.div onClick={() => setDrawerVisibility(!drawerVisibility)} whileTap={{scale: 1}} style={{ display: 'flex'}} whileHover={{scale: 0.9, transition: {type: 'spring', delay: 0.1}}}>
                        <MenuIcon w="22" h="22" color="white"/>
                    </motion.div>
                </Flex>
            </Flex>
            <Flex  display={{base: 'flex', sm: "flex", md:'none'}} >
                <Collapse style={{width: '100%'}} in={drawerVisibility}>
                    {links.map((link) => <MobileNavLink link={link} />)}
                </Collapse>
            </Flex>
        </Flex>
        
       
    </WidthWrap>
    )
}



export default Navbar