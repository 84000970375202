import {Flex} from '@chakra-ui/react'

const WidthWrap = (props) => {

    // TODO add 100% vh mobile styling fix

    return(
    <Flex w="100vw" justifyContent="center" alignItems="center" bg={props.bg}>
        <Flex w="100%" maxW="1250px" bg={props.bg}>
            {props.children}
        </Flex>
    </Flex>
    )
}

export default WidthWrap